import {
  bet9jaProdId,
  bet9jaStgId,
  gliCertificationStgId,
  mamabetProdId,
  mamabetStgId,
  maybetsKenyaProdId,
  maybetsKenyaStgId,
  mozzartbetProdId,
  mozzartbetSerbiaProdId,
  mozzartbetSerbiaStgId,
  mozzartbetCroatiaStgId,
  mozzartbetStgId,
  soccerbetSerbiaProdId,
  soccerbetSerbiaStgId,
  teapuestoPeruProdId,
  teapuestoPeruStgId,
  teapuestoProdId,
  teapuestoStgId,
  yaJuegoProdId,
  yaJuegoStgId,
} from '../../config'
import { OrgIdTypeEnum } from '../../containers/Sidebar/trading/types'

const orgIdGroups: { [key in OrgIdTypeEnum]: string[] } = {
  [OrgIdTypeEnum.BET9JA]: [bet9jaProdId, bet9jaStgId],
  [OrgIdTypeEnum.YAJUEGO]: [yaJuegoProdId, yaJuegoStgId],
  [OrgIdTypeEnum.MOZZARTBET]: [mozzartbetStgId, mozzartbetProdId],
  [OrgIdTypeEnum.MOZZARTBET_SERBIA]: [
    mozzartbetSerbiaStgId,
    mozzartbetSerbiaProdId,
  ],
  [OrgIdTypeEnum.MOZZARTBET_CROATIA]: [
    mozzartbetCroatiaStgId,
  ],  
  [OrgIdTypeEnum.GLI]: [gliCertificationStgId],
  [OrgIdTypeEnum.TEAPUESTO]: [teapuestoProdId, teapuestoStgId],
  [OrgIdTypeEnum.SOCCERBET_SERBIA]: [
    soccerbetSerbiaStgId,
    soccerbetSerbiaProdId,
  ],
  [OrgIdTypeEnum.MAYBETS_KENYA]: [maybetsKenyaStgId, maybetsKenyaProdId],
  [OrgIdTypeEnum.MAMABET]: [mamabetStgId, mamabetProdId],
  [OrgIdTypeEnum.TEAPUESTO_PERU]: [teapuestoPeruStgId, teapuestoPeruProdId],
}

const parseOrgId = (
  organizationId: string | undefined,
): OrgIdTypeEnum | undefined => {
  if (!organizationId) {
    return undefined
  }
  for (const orgType in orgIdGroups) {
    if (orgIdGroups[orgType as OrgIdTypeEnum].includes(organizationId)) {
      return orgType as OrgIdTypeEnum
    }
  }
}

export default parseOrgId
