export enum OrgIdTypeEnum {
  BET9JA = 'bet9ja',
  YAJUEGO = 'yajuego',
  MOZZARTBET = 'mozzartbet',
  MOZZARTBET_SERBIA = 'mozzartbet-serbia',
  GLI = 'gli_certification',
  TEAPUESTO = 'teapuesto',
  TEAPUESTO_PERU = 'teapuesto-peru',
  SOCCERBET_SERBIA = 'soccerbet-serbia',
  MAYBETS_KENYA = 'maybets-kenya',
  MAMABET = 'mamabet',
  MOZZARTBET_CROATIA = 'mozzartbet-croatia',
}
